import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import { convertDate, getYoutubeId } from '../../utils/functions';
import { ArticleProps } from './models';

import './Article.scss';

const Article: FC<{ data: ArticleProps }> = ({
  data: {
    article: { urls, seo, name, date, image, video, body },
    global: { back, hashtag },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  const videoId = video ? getYoutubeId(video) : '';
  const created = convertDate(date);

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="article container section-container pt-5 mt-5">
        <div className="row article__header">
          <div className="col">
            <h2 className="mb-0">{name}</h2>
            <strong>{created}</strong>
            {videoId ? '' : <Image imageData={image} alt={image.altText} className="w-100" />}
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <DangerouslySetInnerHtml html={body} element="div" />
            {videoId ? (
              <div>
                <YouTubeLazyLoaded videoId={videoId} autoplay={false} />
                <div className="pt-4 article__hashtag">
                  <a href={hashtag.url} target="_blank" rel="noreferrer">
                    {hashtag.name}
                  </a>
                  <span>&lt;3</span>
                </div>
              </div>
            ) : (
              ''
            )}
            <hr />
            <div className="pb-5 article__back">
              <Link to={back.url}>
                <i className="fas fa-caret-left me-1" />
                <span>{back.name}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    article(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      name
      date
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      video
      body
    }
    global {
      back {
        name
        url
      }
      hashtag {
        name
        url
      }
    }
  }
`;

export default Article;
